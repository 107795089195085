import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://d7f0b2ce78a19c8b0f7fb5aa53a1586f@o4507727340044288.ingest.us.sentry.io/4507727468691456",
  debug: false,
  environment: "staging",
  release: "e99c19ad64b45c478940d68326f03e73546bf4fc",
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});
import { init } from 'astro/virtual-modules/prefetch.js';init()